@import url('https://fonts.googleapis.com/css2?family=Poiret+One&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.2/font/bootstrap-icons.css);

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  /* display: none; */
}
*::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 25px;
}

*::-webkit-scrollbar-thumb {
  background-color: #4c635e;
  border-radius: 12px;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

.mediumSpan {
  font-size: 1.8rem;
}
.largeSpan {
  font-size: 2.1875rem;
}
.hugeSpan {
  font-size: 2.5rem;
}
.success-animation {
  margin: 150px auto;
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.encabezadoModal {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.cardImage {
  background-position: center !important;
  background-size: cover !important;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5) !important;
  cursor: pointer;
  max-width: 500px;
  min-width: 350px;
}
.cardImage:hover {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.75) !important;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}
